import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import SwitchTransitionGroup from '../components/SwitchTransitionGroup';
import Header from '../Header';
import IntroScreen from '../IntroScreen';
import LevelSelectorScreen from '../LevelSelectorScreen';
import GameScreen from '../GameScreen';
import ContinueScreen from '../ContinueScreen';
import FinishScreen from '../FinishScreen';
import gameScreen from '../store/gameScreen';
import { respondTo } from '../utils/responsive';
import { colors } from '../constants/colors';

const Main = () => {
  const { screen } = useSelector(state => state.gameScreen);
  const dispatch = useDispatch(gameScreen);
  
  function handleEnterLevelScreen() {
    dispatch(gameScreen.actions.setScreen('level'));
  }
  
  function handleEnterGameScreen() {
    playSubmitAudio();
    dispatch(gameScreen.actions.setScreen('game'));
  }

  function handleEnterContinueScreen() {
    playSubmitAudio();
    dispatch(gameScreen.actions.setScreen('continue'));
  }

  function handleEnterFinishScreen() {
    playSubmitAudio();
    dispatch(gameScreen.actions.setScreen('finish'));
  }
  
  function playSubmitAudio() {
    document.getElementById('audio-submit').play();
  }
  
  return (
    <SwitchWrapper watch={screen}>
      <Root>
        <Header />
        { screen === 'intro' && <IntroScreen onStart={handleEnterGameScreen} /> }
        {/* { screen === 'level' && <LevelSelectorScreen onStart={handleEnterGameScreen} /> } */}
        { screen === 'game'  && <GameScreen onFinish={handleEnterContinueScreen}
                                            onGameOver={handleEnterFinishScreen} /> }
        { screen === 'continue' && <ContinueScreen onStart={handleEnterGameScreen} /> }
        { screen === 'finish'   && <FinishScreen onContinue={handleEnterGameScreen} /> }
      </Root>
    </SwitchWrapper>
  )
}

const SwitchWrapper = styled(SwitchTransitionGroup)`
  height: 100%;
`

const Root = styled.div`
  position: relative;
  padding-top: 59px;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  background: ${colors.lightPink};
  ${respondTo.md} {
    padding-top: 48px;
  }
`

export default Main;