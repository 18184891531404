import { useEffect, useState } from 'react';
import Axios from 'axios';

export const useResoucesLoader = (source) => {

  const [ resourceLoaded, setResourceLoaded ] = useState(false);

  useEffect(() => {
    preloader(source, function(count) {
      setResourceLoaded(count / source.length * 100)
    }).then(() => {
      setResourceLoaded(100)
    });
  }, [])
  
  return resourceLoaded;
}

export const preloader = (preload, loadingFunc=null) => {
  const wrap = document.createElement('div');
  return new Promise((resolve, reject) => {
    function loadAsset(nowLoad) {
      const assets = preload;
      
      const instance = Axios.create();
      instance.defaults.timeout = 2000;
    
      instance.get(`${window.location.origin}/${assets[nowLoad]}` )
        .then((item)=> {
    
          // create element
          let elem = document.createElement('img');
          elem.setAttribute('src', item.config.url);
          wrap.append(elem);
    
          // next assets
          if (nowLoad + 1 < assets.length) { 
            loadAsset(nowLoad + 1);
            loadingFunc && loadingFunc(nowLoad);
          }
          else {
            resolve();
          }
        })
        .catch((error)=> {
          // console.log(assets[nowLoad] + ' loaded failed.');
          if (nowLoad + 1 < assets.length) { 
            loadAsset(nowLoad + 1);
            loadingFunc && loadingFunc(nowLoad);
          }
        })
    }
    loadAsset(0);
  })

};
