const colors = {
  pink: "#EBA195",
  lightPink: "#F8E3DF",
  mainColor: "#333",
  black: "#000000",
  red: "#FA4238",
  white: "#FCFCFC",
  gray: "#EFEFEF",
  darkGray: "#D0D0D0",
  highlight: "#FFA243"
}

export { colors };