let isTextInteract = false;

export class TextObject {
  constructor(scene, position, setting) {
    const text = scene.add.text(position.x, position.y, '', setting);
    text.setText(setting.default);
    text.setResolution(2);
    this.text = text;
  }
  update = function(content) {
    this.text.setText(content)
  }
}

export class InteractTextObject extends TextObject {
  constructor(scene, position, setting) {
    super(scene, position, setting);
    if (!isTextInteract) {
      scene.input.on('gameobjectup', (_, gameObject) => gameObject.emit('clicked', gameObject));
      isTextInteract = true;
    }
    this.text.setInteractive();
  }
  onClick = function(clickEvent) {
    this.text.on('pointerdown', function () {
    });
  }
}

export const createText = (scene, position, setting) => {
  const text = scene.add.text(position.x, position.y, '', setting);
  text.setText(setting.default);
  text.update = function (content) {
    text.setText(content)
  }
  return text;
}