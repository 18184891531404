import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import GlobalStyle from './components/GlobalStyle';
import SwitchTransitionGroup from './components/SwitchTransitionGroup';
import { useWordingLoader } from './utils/wordingSystem';
import LoadingScreen from './LoadingScreen';
import SideBar from './SideBar';
import Main from './Main';

import { colors } from './constants/colors';
import { respondTo } from './utils/responsive';

const App = ({ wording }) => {
  const wordingLoaded = useWordingLoader(wording ?? '/wordings/zh-TW.json');
  const [ start, setStart ] = useState(false);
  const realTimeResizerRef = useRef(null);
  const wrapperRef = useRef(null);

  useEffect(() => {
    window.onblur = () => document.getElementById('audio-bgm').pause();
    window.onfocus = () => document.getElementById('audio-bgm').play();
  }, [])

  useEffect(() => {
    function realTimeForResize() {
      handleWatchBrowserResize();
      window.requestAnimationFrame(realTimeForResize);
    }

    if (window.innerWidth <= 768) {
      realTimeResizerRef.current = realTimeForResize();
    }
    return () => window.cancelAnimationFrame(realTimeResizerRef.current);
  }, [])

  function handleWatchBrowserResize() {
    if (!wrapperRef.current) return;
    if (window.innerWidth !== wrapperRef.current.offsetHeight) {
      wrapperRef.current.style.width = `${window.innerWidth}px`;
      wrapperRef.current.style.height = `${window.innerHeight}px`;
    }
  }
  
  function handleEnterGame() {
    setStart(true);
    document.getElementById('audio-bgm').play();
    document.getElementById('audio-submit').play();
  }

  return (
    <Root>
      <Helmet>
        <title>百態自由</title>
        <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@500;700;900&display=swap" rel="stylesheet"></link>
        <link rel="stylesheet" href="https://use.typekit.net/rus1xdd.css"></link>
      </Helmet>
      <GlobalStyle />
      { wordingLoaded &&
        <SwitchTransitionGroup watch={start}>
          <Wrapper ref={wrapperRef}>
            { !start && <LoadingScreen onStart={handleEnterGame} /> }
            { start && <Main /> }
            <SideBar />
          </Wrapper>
        </SwitchTransitionGroup>
      }
      <audio id='audio-bgm' src="/sounds/bgm.mp3" preload='preload' loop={true}/>
      <audio id='audio-submit' src="/sounds/submit.mp3" preload='preload'/>
      <audio id='audio-corrent' src="/sounds/corrent.mp3" preload='preload'/>
      <audio id='audio-wrong' src="/sounds/wrong.mp3" preload='preload'/>
    </Root>
  );
}


const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: '#7C7C7C';
  width: 100vw;
  height: 100vh;
  ${respondTo.md} {
    display: block;
    width: auto;
    height: auto;
  }
`

const Wrapper = styled.div`
  position: relative;
  width: 375px;
  height: 667px;
  max-width: 100%;
  max-height: 100%;
  background: ${colors.white};
  ${respondTo.md} {
    overflow: hidden;
  }
`

export default App;
