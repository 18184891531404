import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { colors } from '../constants/colors';
import { nl2br, _w } from '../utils/wordingSystem';
import Button from '../components/Button';
import ModalBox from '../components/ModalBox';
import hintStatus from '../store/hintStatus';
import Marquee from '../components/Marquee';

const IntroScreen = ({ onStart }) => {
  const wording = _w('intro');
  const { open } = useSelector(state => state.hintStatus);
  const hintDispatch = useDispatch(hintStatus);

  function handleClickCloseModalButton() {
    hintDispatch(hintStatus.actions.closeHint())
    document.getElementById('audio-submit').play();
  }
  
  return (
    <Root>
      <ModalBox open={open}>
        <BoxContent>
          <p>{ nl2br(wording.hint) }</p>
          <ModalButton onClick={handleClickCloseModalButton}>{ wording.close }</ModalButton>
        </BoxContent>
      </ModalBox>
      <Title>{ wording.title }</Title>
      <MarqueeContainer>
        <Marquee><MarqueeImg src="/images/marquee.png" alt="" /></Marquee> 
      </MarqueeContainer>
      <Bottom>
        <Content>
          { wording.content }
        </Content>
        <StartButton onClick={onStart}>{ wording.start }</StartButton>
      </Bottom>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  background: ${colors.lightPink};
  box-sizing: border-box;
`

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 20px 40px;
  padding-bottom: 48px;
  box-sizing: border-box;
`

const Title = styled.div`
  position: relative;
  padding: 0 40px;
  margin-top: 40px;

  box-sizing: border-box;

  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  white-space: pre-line;
  
`

const MarqueeContainer = styled.div`
    margin-top: 24px;
`

const MarqueeImg = styled.img`
    height: 160px;
`

const Content = styled.div`
  font-size: 14px;
  line-height: 24px;
  white-space: break-spaces;
`

const BoxContent = styled.div`
  border-radius: 10px;
  width: 240px;
  padding: 40px 24px;
  font-size: 14px;
  text-align: center;
  background: ${colors.white};
  > p {
    margin-bottom: 24px;
  }
`

const ModalButton = styled(Button)`
  min-width: 160px;
  margin-top: 40px;
`

const StartButton = styled(Button)`
  width: 100%;
`

export default IntroScreen;