import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css, keyframes } from 'styled-components';
import { core } from '../GameCore';
import gameStatus from '../store/gameStatus';
import gameScreen from '../store/gameScreen';
import ModalBox from '../components/ModalBox';
import Button from '../components/Button';
import { colors } from '../constants/colors'
import useSvgResouceGetter from '../utils/useSvgResouceGetter';
import { _w } from '../utils/wordingSystem';

const GameScreen = ({ onFinish, onGameOver }) => {
  const wording = _w('game');
  const { allLevel, playLevel, challenge } = useSelector(state => state.gameStatus);
  const [ modalOpen, setModalOpen] = useState(true);
  const { loadingStatus } = useSelector(state => state.gameScreen);
  const data = wording[`level${playLevel}`] || null;
  const resouces = useSvgResouceGetter(data.resouces);
  const dispatch = useDispatch(gameStatus);

  useEffect(() => {
    dispatch(gameScreen.actions.setLoadingStatus(true));
  }, []);
  
  useEffect(() => {
    if (resouces) {
      core({
        resouces: resouces,
        goal: handleGetGoalToWin(resouces),
        background: data.background,
        onFinish: handleFinishGame
      });
    }
  }, [resouces])


  function handleGetGoalToWin(resouces) {
    let goalToWin;
    if (challenge) {
      goalToWin = resouces.length;
    }
    else {
      goalToWin = wording[`level${playLevel}`].goal
    };

    return goalToWin;
  }

  function handleFinishGame() {
    dispatch(gameStatus.actions.levelComplete());

    if (playLevel >= allLevel) onGameOver();
    else onFinish()
  }
  
  function handleClickCloseModalButton() {
    setModalOpen(false);
    document.getElementById('audio-submit').play();
  }

  return (
    <Root>
      { !loadingStatus &&
        <ModalBox open={modalOpen}>
          <BoxContent>
            <p>{ wording.hint }</p>
            <ModalButton onClick={handleClickCloseModalButton}>GO</ModalButton>
          </BoxContent>
        </ModalBox>
      }
      <LoadingAnimation hide={!loadingStatus}>
        <div></div>
        <div></div>
        <div></div>
      </LoadingAnimation>
      <GameContainer id="game-container" show={!loadingStatus}></GameContainer>
    </Root>
  );
}

const Root = styled.div`
  #game-container {
    canvas {
      width: 100%;
      height: 100%;
    }
  }
`

const GameContainer = styled.div`
  opacity: 0;
  transition: opacity .3s ease;
  ${({ show }) => show && css`opacity: 1;`}
`

const circleScale = keyframes`
  from { transform: scale(0) }
  50% { transform: scale(1) }
  to { transform: scale(0) }
`

const LoadingAnimation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  visibility: visible;
  background: ${colors.pink};
  transition: all .1s ease;
  ${({ hide }) => hide && css`visibility: hidden; opacity: 0;`};
  > div {
    margin: 0 6px;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transform: scale(0);
    background: ${colors.white};
    animation: ${circleScale} 1s ease infinite;
    &:first-child { animation-delay: 0s }
    &:nth-child(2) { animation-delay: .3s }
    &:nth-child(3) { animation-delay: .6s }
  }
`

const BoxContent = styled.div`
  border-radius: 10px;
  width: 260px;
  padding: 40px 24px;
  font-size: 14px;
  text-align: center;
  background: ${colors.white};
  > p {
    margin-bottom: 24px;
    white-space: break-spaces;
  }
`

const ModalButton = styled(Button)`
    min-width: 160px;
`


export default GameScreen;
