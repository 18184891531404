export class CameraController {
  /**
   * @param {Phaser.Scene} scene 
   * @param {*} background 
   */
  constructor(scene, background) {
    
    const { width: canvasWidth, height: canvasHeight } = scene.sys.game.canvas;
    scene.cameras.main.setBounds(0, 0, background.displayWidth, background.displayHeight);

    // Camera setting
    this.camera = {
      zoom: 1,
      move: false,
      moving: false,
      start: null,
      prev: null,
      x: scene.cameras.main.centerX,
      y: scene.cameras.main.centerY,
      max: {x: background.displayWidth - (canvasWidth/2), y: background.displayHeight - (canvasHeight/2)},
      min: {x: canvasWidth/2, y: canvasHeight/2},
    }


    // Apply Interact for Camera
    this.applyInteract = function() {
      scene.input.on('wheel', handleWheel, scene);
      scene.input.on('pointerdown', handleEnableMove, scene);
      scene.input.on('pointerup',   handleDisableMove, scene);
      scene.input.on('pointermove', handleMoveCamera, scene);
    }

    // Disable Interact for Camera
    this.removeInteract = function() {
      scene.input.off('wheel');
      scene.input.off('pointerdown');
      scene.input.off('pointerup');
      scene.input.off('pointermove');
    }


    const handleEnableMove = (pointer) => {
      this.camera.move = true;

      this.camera.start = {
        x: pointer.position.x,
        y: pointer.position.y
      };
    }

    const handleDisableMove = () => {
      this.camera.move = false;
      this.camera.moving = false;

      if (this.camera.prev) {
        this.camera.x = this.camera.prev.x;
        this.camera.y = this.camera.prev.y;
      }
    }
    
    const handleMoveCamera = (pointer) => {
      if (this.camera.move) {
        this.camera.moving = true;
        
        const { x, y } = pointer.position;
        const deltaX = x - this.camera.start.x;
        const deltaY = y - this.camera.start.y;
        const moveX = Math.min(Math.max((this.camera.x - deltaX), this.camera.min.x), this.camera.max.x);
        const moveY = Math.min(Math.max((this.camera.y - deltaY), this.camera.min.y), this.camera.max.y);
        scene.cameras.main.pan(moveX, moveY, 0);

        this.camera.prev = { x: moveX, y: moveY };
      }
    }

    const handleWheel = (pointer) => {
        const { deltaX, deltaY } = pointer;
        
        const moveX = Math.min(Math.max((this.camera.x + deltaX), this.camera.min.x), this.camera.max.x);
        const moveY = Math.min(Math.max((this.camera.y + deltaY), this.camera.min.y), this.camera.max.y);

        scene.cameras.main.pan(moveX, moveY, 0);
        this.camera.x = moveX;
        this.camera.y = moveY;
        this.camera.prev = { x: moveX, y: moveY };
    }

    // Scroll to scale camera
    function handleWheelToZoom(pointer, gameObjects, deltaX, deltaY, deltaZ) {
      this.camera.zoom = Math.min(Math.max(this.camera.zoom + deltaY/1000, 1), 1.5);
      scene.cameras.main.setZoom(this.camera.zoom);
      
      this.camera.max = {
        x: background.displayWidth - (canvasWidth/2/this.camera.zoom),
        y: background.displayHeight - (canvasHeight/2/this.camera.zoom),
      };
      this.camera.min = {
        x: canvasWidth/2/this.camera.zoom,
        y: canvasHeight/2/this.camera.zoom,
      }
    }

    
  }
}
