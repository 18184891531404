import Phaser from 'phaser';
import store from '../store';
import gameScreen from '../store/gameScreen';

export class PreloadScene extends Phaser.Scene
{
  constructor () {
      super({ key: 'PreloadScene' });
  }

  preload () {
    //  Start loading Process
    this.load.on(Phaser.Loader.Events.COMPLETE, () => {
      store.dispatch(gameScreen.actions.setLoadingStatus(false));
    });

    // Game Objects
    const { resouces } = this.game;
    resouces.forEach(resouce => {
      this.load.image(resouce.name, `assets/${resouce.name}.png`);
    })

    // Game Background
    const { background } = this.game;
    this.load.image('bg', background.asset);

    // Game UI
    this.load.svg('frame', 'assets/game-hint-frame.svg');

  }

  create () {
    this.scene.start('GameScene');
    this.scene.start('UIScene');
  }

}
