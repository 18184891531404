import { createStore, combineReducers } from 'redux';
import gameStatus from './gameStatus';
import sidebarStatus from './sidebarStatus';
import hintStatus from './hintStatus';
import gameScreen from './gameScreen';
import audio from './audio';

const reducer = combineReducers({
  gameStatus: gameStatus.reducer,
  gameScreen: gameScreen.reducer,
  sidebarStatus: sidebarStatus.reducer,
  hintStatus: hintStatus.reducer,
  audio: audio.reducer,
});

const store = createStore(reducer, window.__REDUX_DEVTOOLS_EXTENSION__?.());

export default store;