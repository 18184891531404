import { createSlice } from "@reduxjs/toolkit";

const hintStatus = createSlice({
    name: 'hintStatus',
    initialState: {
      'open': true
    },
    reducers: {
      openHint: (state) => {
        state.open = true;
      },
      closeHint: (state) => {
        state.open = false;
      }
    }
});

export default hintStatus;
