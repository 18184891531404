import { createSlice } from "@reduxjs/toolkit";

const sidebarStatus = createSlice({
    name: 'sidebarStatus',
    initialState: {
      'open': false
    },
    reducers: {
      openSidebar: (state) => {
        state.open = true;
      },
      closeSidebar: (state) => {
        state.open = false;
      }
    }
});

export default sidebarStatus;
