import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import { colors } from '../constants/colors';
import LevelList from './LevelList';
import Button from '../components/Button';
import gameStatus from '../store/gameStatus';
import { _w } from '../utils/wordingSystem';

const LevelSelectorScreen = ({ onStart }) => {
  const levelRef = useRef(null);
  const wording = _w('game.level')
  const { allowLevel, allowChallenge } = useSelector(state => state.gameStatus);
  const dispatch = useDispatch(gameStatus);
  
  function handleStartGame() {
    const level = levelRef.current;
    dispatch(gameStatus.actions.selectLevel(level));
    dispatch(gameStatus.actions.setChallenge(false));
    onStart();
  }

  function handleStartGameWithChallengeMode() {
    const level = levelRef.current;
    dispatch(gameStatus.actions.selectLevel(level));
    dispatch(gameStatus.actions.setChallenge(true));
    onStart();
  }
  
  return (
    <Root>
      <LevelList allow={allowLevel} ref={levelRef}  />
      <Bottom>
        { allowChallenge &&
          <Buttonbar>
            <GoButton onClick={handleStartGame}>{ wording.normal }</GoButton>
            <ChallengeButton onClick={handleStartGameWithChallengeMode}>{ wording.challenge }</ChallengeButton>
          </Buttonbar>
        }
        {
          !allowChallenge &&
          <GoButton onClick={handleStartGame}>{ wording.go }</GoButton>
        }
      </Bottom>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 100%;
  background: ${colors.pink};
  box-sizing: border-box;
`

const Bottom = styled.div`
  padding: 30px 40px;
  box-sizing: border-box;
`

const GoButton = styled(Button)`
  width: 100%;
  font-size: 14px;
  font-family: protipo;
  text-transform: uppercase;
  font-weight: 500;
`

const Buttonbar = styled.div`
  display: flex;
  justify-content: space-between;
  > button {
    width: calc(50% - 4px);
  }
`

const bounce = keyframes`
  66% { transform: scale(1); }
  77%  { transform: scale(0.95); }
  88%  { transform: scale(1.05); }
  to   { transform: scale(1); }
`

const ChallengeButton = styled(GoButton)`
  animation: ${bounce} 2s ease infinite;
`

export default LevelSelectorScreen;