import { createSlice } from "@reduxjs/toolkit";

// methods
function updateLocalStorage(data) {
  localStorage.setItem('annual-calendar-data', JSON.stringify(data));
}

// localStorage data
let storeData = window.localStorage.getItem('annual-calendar-data') || null;
storeData = storeData ? JSON.parse(storeData) : {
  'allLevel': 3,
  'playLevel': 1,
  'allowLevel': 1,
  'challenge': false,
  'allowChallenge': false,
};

const gameStatus = createSlice({
    name: 'gameStatus',
    // initialState: storeData,
    initialState: {
      'allLevel': 1,
      'playLevel': 1,
      'allowLevel': 1,
      'challenge': false,
      'allowChallenge': false,
    },
    reducers: {
      levelComplete: (state) => {
        if (state.playLevel === state.allLevel) return;
        if (state.playLevel+1 > state.allowLevel) {
          state.allowLevel = state.playLevel+1;
        };
        state.playLevel += 1;
        updateLocalStorage(state);
      },
      selectLevel: (state, action) => {
        state.playLevel = action.payload;
        updateLocalStorage(state);
      },
      setChallenge: (state, action) => {
        state.challenge = action.payload;
        updateLocalStorage(state);
      },
      setAllowChallenge: (state, action) => {
        state.allowChallenge = action.payload;
        updateLocalStorage(state);
      }
    }
});

export default gameStatus;
