export const SoundOn = ({ ...props }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path id="Path_9" data-name="Path 9" d="M0,0H24V24H0Z" fill="none"/>
      <path id="Path_10" data-name="Path 10" d="M10,7.22,6.6,10H3v4H6.6L10,16.78ZM5.889,16H2a1,1,0,0,1-1-1V9A1,1,0,0,1,2,8H5.889l5.294-4.332A.5.5,0,0,1,12,4.055v15.89a.5.5,0,0,1-.817.387L5.89,16Zm13.517,4.134L17.99,18.718A9,9,0,0,0,17.7,5.032l1.42-1.42a11,11,0,0,1,.29,16.522Zm-3.543-3.543-1.422-1.422a4,4,0,0,0-.321-6.561l1.439-1.439a6,6,0,0,1,.3,9.422Z" fill="currentColor"/>
    </svg>
  )
}

export const SoundOff = ({ ...props }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path id="Path_21" data-name="Path 21" d="M0,0H24V24H0Z" fill="none"/>
      <path id="Path_22" data-name="Path 22" d="M10,7.22,6.6,10H3v4H6.6L10,16.78ZM5.889,16H2a1,1,0,0,1-1-1V9A1,1,0,0,1,2,8H5.889l5.294-4.332A.5.5,0,0,1,12,4.055v15.89a.5.5,0,0,1-.817.387L5.89,16Zm14.525-4,3.536,3.536L22.536,16.95,19,13.414,15.464,16.95,14.05,15.536,17.586,12,14.05,8.464,15.464,7.05,19,10.586,22.536,7.05,23.95,8.464Z" fill="currentColor"/>
    </svg>
  )
}

export const Menu = ({ ...props }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path id="Path_7" data-name="Path 7" d="M0,0H24V24H0Z" fill="none"/>
      <path id="Path_8" data-name="Path 8" d="M18,18v2H6V18Zm3-7v2H3V11ZM18,4V6H6V4Z" fill="currentColor"/>
    </svg>
  )
}

export const Prev = ({ ...props }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path id="Path_11" data-name="Path 11" d="M0,0H24V24H0Z" fill="none"/>
      <path id="Path_12" data-name="Path 12" d="M10.828,12l4.95,4.95-1.414,1.414L8,12l6.364-6.364L15.778,7.05Z"/>
    </svg>
  )
}

export const Lock = ({ ...props }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path id="Path_25" data-name="Path 25" d="M0,0H24V24H0Z" fill="none"/>
      <path id="Path_26" data-name="Path 26" d="M7,10H20a1,1,0,0,1,1,1V21a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V11a1,1,0,0,1,1-1H5V9A7,7,0,0,1,18.262,5.869l-1.789.894A5,5,0,0,0,7,9Zm3,5v2h4V15Z" fill="currentColor"/>
    </svg>
  )
}

export const Cart = ({ ...props }) => {
  return (
    <svg width="13.519" height="13.794" viewBox="0 0 13.519 13.794" {...props}>
      <path id="Path_20" data-name="Path 20" d="M2.855,4.781.757,2.676l.915-.919,2.1,2.106h9.86a.649.649,0,0,1,.62.836L12.7,9.894a.647.647,0,0,1-.62.463H4.149v1.3h7.116v1.3H3.5a.648.648,0,0,1-.647-.649Zm1.294.381v3.9H11.6l1.164-3.9Zm-.323,10.39a.974.974,0,1,1,.97-.974A.972.972,0,0,1,3.825,15.551Zm7.763,0a.974.974,0,1,1,.97-.974A.972.972,0,0,1,11.588,15.551Z" transform="translate(-0.757 -1.757)" fill="currentColor"/>
    </svg>
  )
}

export const Challenge = ({ ...props }) => {
  return (
    <svg width="14" height="10" viewBox="0 0 14 10" {...props}>
      <path id="Path_30" data-name="Path 30" d="M8.636,10.743v1.146h3.182V13H4.182V11.889H7.364V10.743a4.7,4.7,0,0,1-4.455-4.41V3H13.091V6.333a4.7,4.7,0,0,1-4.454,4.41ZM4.182,4.111V6.333A3.6,3.6,0,0,0,8,9.667a3.6,3.6,0,0,0,3.818-3.333V4.111ZM1,4.111H2.273V6.333H1Zm12.727,0H15V6.333H13.727Z" transform="translate(-1 -3)" fill="currentColor" />
    </svg>
  )
}

export const Share = ({ ...props }) => {
  return (
    <svg width="13.457" height="12.989" viewBox="0 0 13.457 12.989" {...props}>
      <path id="Path_28" data-name="Path 28" d="M6.5,2.144V3.42H3.286v8.93H13.574V6.609H14.86v6.378a.64.64,0,0,1-.643.638H2.643A.64.64,0,0,1,2,12.987V2.782a.64.64,0,0,1,.643-.638ZM12.9,3.42,11,1.538l.909-.9,3.434,3.406a.38.38,0,0,1,.083.417.386.386,0,0,1-.356.236H9.716A1.281,1.281,0,0,0,8.43,5.971V9.8H7.144V5.971A2.562,2.562,0,0,1,9.716,3.42Z" transform="translate(-2 -0.636)" fill="currentColor"/>
    </svg>
  )
}

export const Close = ({ ...props }) => {
  return (
    <svg width="12.728" height="12.728" viewBox="0 0 12.728 12.728" {...props}>
      <path id="Path_18" data-name="Path 18" d="M12,10.586l4.95-4.95L18.364,7.05,13.414,12l4.95,4.95L16.95,18.364,12,13.414l-4.95,4.95L5.636,16.95,10.586,12,5.636,7.05,7.05,5.636Z" transform="translate(-5.636 -5.636)" fill="currentColor"/>
    </svg>

  )
}

