import { fadeAnimation } from "./fadeAnimation";

export class GameObject {
  constructor(scene, data) {
    const { name, x, y, width, height } = data;
    const object = scene.add.image(x*2, y*2, name);
    object.displayWidth = width * 2;
    object.displayHeight = height * 2;

    this.name = name;
    this.object = object;
  }
}

export class InteractGameObject extends GameObject  {
  constructor(scene, data) {
    super(scene, data);
    this.scene = scene;
    this.status = 'enable';
    this.object.setInteractive();
  }
  onClick(clickEvent) {
    const self = this;

    this.ckickEvent = function() {
      const clickBinder = clickEvent.bind(this);
      clickBinder(self);
    }
    
    this.object.on('clicked', this.ckickEvent, this.scene);
  }
  
  off() {
    this.status = 'disable';
    this.object.off('clicked', this.ckickEvent);
    this.object.input.enabled = false;
    fadeAnimation(this.object);
  }
}