
function easeInOutQuart(x) {
  return x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2;
}

export function fadeAnimation(item, func) {
  let count = 0;
  let animationTimer;
  const all = 30;
  const half = 15;
  const animationObject = item;
  
  
  function animation() {
    const scale = count < half ?
    1 - easeInOutQuart(count/half)
    :
    easeInOutQuart((count-half)/half)

    if (count === half && func) func();

    animationObject.setAlpha(scale);
    count += 1;

    if (count < all) {
      window.requestAnimationFrame(animation);
    }
    else {
      window.cancelAnimationFrame(animationTimer);
    };
  }
  
  animation();
}