import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../constants/colors';
import { Lock } from '../Icons';

const LevelButton = ({ selected, enable, background, data, ...props }) => {
  return (
    <Root bg={background} selected={selected} {...props}>
      <Title>{ data.title }</Title>
      <Background enable={enable} src={data.image} alt="" />
      { enable && <Lock className="icon" /> }
    </Root>
  )
}

const Root = styled.button`
  position: relative;
  border: 0;
  border-radius: 6px;
  padding: 30px;
  width: 100%;
  background: ${colors.lightPink};
  box-sizing: border-box;
  ${({ selected }) => selected && css`
    opacity: 1;
    background: ${colors.white};
    box-shadow: 0px 3px 6px hsla(0, 0%, 0%, .16);
  `}
  > .icon {
    position: absolute;
    top: 50%;
    right: 12px;
    color: ${colors.pink};
    transform: translateY(-50%);
  }
`

const Title = styled.span`
  font-size: 16px;
  font-family: protipo;
  font-weight: 500;
`

const Background = styled.img`
  display: block;
  position: absolute;
  top: 0;
  left: 8px;
  height: 100%;
  width: auto;
  ${({ enable }) => !enable && css` opacity: 0.5`};
`

export default LevelButton;