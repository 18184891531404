import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { Close, Cart } from '../components/Icons';
import { colors } from '../constants/colors';
import { _w } from '../utils/wordingSystem';
import sidebarStatus from '../store/sidebarStatus';


const SideBar = () => {
  const wording = _w('about');
  const { open } = useSelector(state => state.sidebarStatus);
  const dispatch = useDispatch(sidebarStatus);

  function handleClickCloseButton() {
    dispatch(sidebarStatus.actions.closeSidebar())
    document.getElementById('audio-submit').play();
  }

  return (
    <Root open={open}>
      <Mask open={open} />
      <Content open={open}>
        <CloseButton onClick={handleClickCloseButton}><Close /></CloseButton>
        <div>
          { wording.section.map((item, i) => 
            <div className="section" key={i}>
              <div className="title">{item.title}</div>
              <div className="content">{item.content}</div>
            </div>
          ) }
          <Button href={wording.buy_link} target="_blank">
            <span>{ wording.buy }</span>
            <Cart />
          </Button>
        </div>
        <div className="copyright">
          { wording.copyright }
        </div>
      </Content>
    </Root>
  )
}

const Root = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  ${({ open }) => open && css`pointer-events: auto;`}
  overflow: hidden;
`

const Mask = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: ${colors.pink};
  z-index: -1;
  opacity: 0;
  transition: opacity .3s ease;
  ${({ open }) => open && css`opacity: 1;`}
`
const Content = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  border-radius: 24px 0px 0px 24px;
  flex-direction: column;
  justify-content: space-between;
  padding: 48px 40px;
  padding-top: 80px;
  width: 95%;
  height: 100%;
  background: #1C1412;
  box-sizing: border-box;
  transform: translateX(105%);
  transition: transform .3s ease;
  ${({ open }) => open && css`transform: translateX(0);`}
  .section {
    margin-bottom: 24px;
    .title {
      margin-bottom: 8px;
      color: #EBA195;
      font-size: 16px;
    }
    .content {
      color: #D5D6D6;
      white-space: break-spaces;
      font-size: 14px;
      line-height: 24px;
    }
  }
  .copyright {
    text-align: left;
    line-height: 20px;
    white-space: break-spaces;
    font-family: protipo;
    font-size: 12px;
    font-weight: 500;
    color: #D5D6D6;
  }
`

const Button = styled.a`
  display: block;
  border: 1px solid ${colors.pink};
  border-radius: 6px;
  padding: 12px;
  color: ${colors.pink};
  text-align: center;
  span {margin-right: 6px;}
`

const CloseButton = styled.button`
  position: absolute;
  right: 12px;
  top: 12px;
  border: 0;
  padding: 12px;
  background: transparent;
  color: ${colors.white};
`


export default SideBar;