import React from 'react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { SoundOff, SoundOn, Menu } from '../components/Icons';
import { colors } from '../constants/colors';
import gameScreen from '../store/gameScreen';
import audio from '../store/audio';
import sidebarStatus from '../store/sidebarStatus';

import { respondTo } from '../utils/responsive';
import { _w } from '../utils/wordingSystem';

const Header = () => {
  const wording = _w('header');

  const { playLevel } = useSelector(state => state.gameStatus);
  const { screen } = useSelector(state => state.gameScreen);
  const muted = useSelector(state => state.audio.muted);

  const dispatch = useDispatch();

  const showPrev = useMemo(() => {
    return screen === 'game' || screen === 'level';
  }, [screen]);

  const title = useMemo(() => {
    if (screen === 'level') {
      return wording.title['level'];
    }
    else if (screen === 'game') {
      return wording.title[`game${playLevel}`];
    }
  }, [screen])
  
  function handleClickPrevButton() {
    if (screen === 'level') {
        dispatch(gameScreen.actions.setScreen('intro'))
    }
    // else if (screen === 'game') {
    //     dispatch(gameScreen.actions.setScreen('intro'))
    // }
  }

  function handleClickBurgerButton() {
    dispatch(sidebarStatus.actions.openSidebar())
    document.getElementById('audio-submit').play();
  }

  function handleSoundClick() {
    dispatch(audio.actions.toggleMuted());
  }

  
  return (
    <Root>
      <Button onClick={handleClickPrevButton}>
        <img src="images/header-logo.svg" alt="" />
      </Button>
      {/* { showPrev && <>
        <img src="images/header-logo.svg" alt="" />
        <Button onClick={handleClickPrevButton}><Prev /></Button>
        <Title>{ title }</Title> 
      </>} */}
      <Tools>
        <Button onClick={handleSoundClick}>{ muted ? <SoundOff/> : <SoundOn/> }</Button>
        <Button onClick={handleClickBurgerButton}><Menu /></Button>
      </Tools>
    </Root>
  )
}

const Root = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #EDB7AF;
  padding: 14px 24px;
  background: ${colors.pink};
  box-sizing: border-box;
  z-index: 1;
  ${respondTo.md} {
    position: fixed;
    padding: 8px 24px;
    height: 48px;
  }
`

const Tools = styled.div`
    
`

const Button = styled.button`
  border: 0;
  background: transparent;
  color: ${colors.black};
`

const Title = styled.div`
  font-size: 16px;
  font-family: protipo;
  text-transform: uppercase;
  font-weight: 600;

`

export default Header;