import Phaser from 'phaser';
import { createText } from './createText';
import { fadeAnimation } from './fadeAnimation';

export class GameHint {
  constructor(scene, goal) {

    // frame
    const frame = scene.add.image(150, 120, 'frame');
    frame.displayWidth = frame.width * 2;
    frame.displayHeight = frame.height * 2;
    
    // image
    const image = scene.add.image(120, 120);
    image.displayWidth = image.width / 2;
    image.displayHeight = image.height / 2;
    image.setScale(0.1);
    
    this.image = image;

    // text
    const text = createText(scene, {x: 0, y: 0}, { font: '28px', fill: '#FFF', default: `1/${goal}`, align: 'center' });
    text.setOrigin(0.5, 1);
    text.setPosition(218, 78)
    this.updateCount = (num) => { text.update(`${num}/${goal}`) };

    this.index = 0;

    this.change = ({resouce, useAnimation}) => {
      const { name, width, height } = resouce;

      const changeTexture = () => {
        this.image.setTexture(name);
        this.image.displayWidth = width * 2;
        this.image.displayHeight = height * 2;
        this.image.setScale(this.image.scale * 0.8);
      }

      if (useAnimation) {
        fadeAnimation(this.image, changeTexture);
      }
      else {
        changeTexture();
      }
    }
    
  }
}

export class UIScene extends Phaser.Scene
{
  constructor () {
    super({ key: 'UIScene', active: true });
  }

  create () {
    const { resouces, goal } = this.game;

    this.hint = new GameHint(this, goal);
    this.hint.image.setTexture(resouces[0].name);
  }
  
  update () {
    const gameScene = this.scene.get('GameScene');
    const { resouces, goal } = this.game;
    const { currentIndex } = gameScene;

    if (this.index !== currentIndex && currentIndex < goal) {
      this.index = currentIndex;
      this.hint.change({
        resouce: resouces[currentIndex],
        useAnimation: currentIndex !== 0
      });
      this.hint.updateCount(currentIndex+1);
    };
  }
}
