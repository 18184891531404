import { createSlice } from "@reduxjs/toolkit";

const audio = createSlice({
    name: 'audio',
    initialState: {
        muted: false
    },
    reducers: {
        toggleMuted: (state, action) => {
            state.muted = !state.muted;
            document.getElementById('audio-bgm').muted = state.muted;
            document.getElementById('audio-submit').muted = state.muted;
            document.getElementById('audio-corrent').muted = state.muted;
            document.getElementById('audio-wrong').muted = state.muted;
        }
    }
});

export default audio;
