import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { colors } from '../constants/colors';
import Button from '../components/Button';

import { _w, nl2br } from '../utils/wordingSystem';
import { useMemo } from 'react';
import { Cart, Challenge, Share } from '../components/Icons';
import gameStatus from '../store/gameStatus';
import { shareToFB } from '../utils/socialMediaShare';

const FinishScreen = ({ onContinue }) => {
  const wording = _w('finish');
  const index = Math.floor(Math.random() * wording.result.length);
  const { allLevel, playLevel, allowChallenge } = useSelector(state => state.gameStatus);
  const dispatch = useDispatch(gameStatus);

  const currentResult = useMemo(() => {
    // if (!allowChallenge) {
    //   return wording.challenge;
    // }
    // else {
    //   return wording.result[index];
    // }
    return wording.result[index];
  }, [wording])

  function handleClickPlayButton() {
    onContinue();
    if (playLevel === allLevel) {
      // dispatch(gameStatus.actions.setAllowChallenge(true));
      dispatch(gameStatus.actions.setChallenge(true));
    }
  }

  function handleClickSocialShareButton() {
    if (playLevel === allLevel) {
      shareToFB(`${window.location}/share-result/${index+1}`);
    }
    else {
      shareToFB();
    }
  }
  
  return (
    <Root>
      <Title>
        <img src={currentResult.background} alt="" />
        <div className="text">{ nl2br(currentResult.title) }</div>
      </Title>
      <Content>
        { currentResult.content }
      </Content>
      <Bottom>
        <Buttonbar>
          <MoreButton className="play" theme="white" onClick={handleClickPlayButton}>
            <span>{ !allowChallenge ? wording.more : wording.play }</span>
            <Challenge />
          </MoreButton>
          <Button className="share" theme="white" onClick={handleClickSocialShareButton}>
            <span>{ wording.share }</span>
            <Share />
          </Button>
        </Buttonbar>
        <BuyButton as="a" target="_blank" href={wording.buy_link}>
          <span>{ wording.buy }</span>
          <Cart />
        </BuyButton>
      </Bottom>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 16px;
  height: 100%;
  background: ${colors.white};
  box-sizing: border-box;
`

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  padding: 20px 40px;
  padding-bottom: 48px;
  box-sizing: border-box;
`

const Title = styled.div`
  position: relative;
  margin-top: 16px;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 18px;
  background: ${colors.pink};
  background-image: radial-gradient(circle at center, #EDB7AF 20%, transparent 22%); 
  background-size: 6px 6px;
  box-sizing: border-box;
  img {
    display: block;
    margin: auto;
    max-width: 100%;
    height: auto;
  }
  > .text {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
  }
`

const Content = styled.div`
  font-size: 14px;
  line-height: 24px;
  white-space: break-spaces;
`

const Buttonbar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    span {margin-right: 4px;}
    &.play { width: calc(65% - 6px) }
    &.share { width: 35% }
  }
`

const MoreButton = styled(Button)`
    >span {
        white-space: pre-line;
    }
`

const BuyButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  span {
    margin-right: 4px;
  }
`

export default FinishScreen;