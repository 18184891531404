import styled, { css } from 'styled-components';
import { colors } from "../../constants/colors";

const Button = styled.button`
  border: 1px solid;
  border-color: transparent;
  border-radius: 6px;
  background: ${colors.black};
  color: ${colors.white};
  font-size: 14px;
  padding: 12px;
  font-family: protipo;
  box-sizing: border-box;
  
  ${({ theme }) => theme === 'white' && css`
    color: ${colors.black};
    border-color: ${colors.black};
    background: transparent;
  `} 
`

export default Button;