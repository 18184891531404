import React, { useEffect, useRef, useState } from 'react'
import styled, { css, keyframes } from 'styled-components';
import { respondTo } from '../../utils/responsive';

const Marquee = ({ children, ...props }) => {
  const [ start, setStart ] = useState(false);
  const marqueeRef = useRef(null);
  const rootRef = useRef(null);

  useEffect(() => {
    const imgs = marqueeRef.current.querySelectorAll('img');
    imgs.forEach((img, i) => img.onload = () => {
      if (i === imgs.length-1) setStart(true);
      handleResize()
    });
  }, [])

  function handleResize() {
    rootRef.current.style.height = `${marqueeRef.current.offsetHeight}px`;
  }

  return (
    <Root ref={rootRef} {...props}>
      <MarqueeContent active={start} ref={marqueeRef}>{ children }{ children }</MarqueeContent>
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  height: 100px;
  overflow: hidden;
  ${respondTo.md} {
    height: 56px;
  }
`

const move = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
`

const MarqueeContent = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
  overflow: hidden;
  transition: transform .1s ease;
  ${({active}) => active && css`animation: ${move} 20s linear infinite;`}
  img {
    min-height: 56px;
    /* margin-right: 24px */
  }
`

export default Marquee;
  