import { useEffect, useState } from 'react';


function getPosition(element) {
  const str = element.getAttribute('transform');
  const rangeFront = str.indexOf('(')+1;
  const rangeEnd = str.indexOf(')');
  const result = str.slice(rangeFront, rangeEnd).split(' ').map(item => Number(item));
  return {x: result[0], y: result[1]};
}


const useSvgResouceGetter = (url) => {
  const [ resouces, setResouces ] = useState(null);
  useEffect(() => url && handleLoadResouces(), []);

  function handleLoadResouces() {
    fetch(url)
    .then(res => res.text())
    .then(svg => {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(svg, "text/xml");
      const elements = xmlDoc.querySelectorAll('rect[data-name]');
      const results = [];
      elements.forEach(element => {
        const { name } = element.dataset;
        let { x, y } = getPosition(element);
        const width = Number(element.getAttribute('width'));
        const height = Number(element.getAttribute('height'));
        x = x + width/2;
        y = y + height/2;
        
        results.push({ name, width, height, x, y });
      })

      setResouces(results);
    })
  }
  
  return resouces;
}


export default useSvgResouceGetter;