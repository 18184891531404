import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { colors } from '../constants/colors';
import Button from '../components/Button';

import { _w, nl2br } from '../utils/wordingSystem';

const ContinueScreen = ({ onStart }) => {
  const wording = _w('continue');
  const { playLevel } = useSelector(state => state.gameStatus);

  const data = useMemo(() => {
    return wording[`continue_to_level${playLevel}`];
  }, [playLevel]);

  function handleClickContinueButton() {
    onStart();
  }

  return (
    <Root>
      <Title>
        <img src="/images/continue-title.svg" alt="" />
        <div className="text">{ nl2br(data.title) }</div>
      </Title>
      <Bottom>
        <Content>{ data.content }</Content>
        <StartButton onClick={handleClickContinueButton}>Continue</StartButton>
      </Bottom>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  background: ${colors.pink};
  background-image: radial-gradient(circle at center, #EDB7AF 20%, transparent 22%); 
  background-size: 6px 6px;
  box-sizing: border-box;
`

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 20px 40px;
  padding-bottom: 48px;
  box-sizing: border-box;
`

const Title = styled.div`
  position: relative;
  margin-top: 15vh;
  padding: 18px;
  box-sizing: border-box;
  img {
    display: block;
    margin: auto;
    max-width: 100%;
    height: auto;
    min-height: 100px;
  }
  > .text {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
  }
`

const Content = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  white-space: break-spaces;
`

const StartButton = styled(Button)`
  width: 100%;
`

export default ContinueScreen;