import React from 'react';
import styled from 'styled-components';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

const SwitchTransitionGroup = ({ watch, children, ...props }) => {
  return (
    <Root {...props}>
      <CSSTransition
        key={ watch }
        timeout={300}
        classNames={'overlay'}
        unmountOnExit>
        { children }
      </CSSTransition>
    </Root>
  )
}

const Root = styled(TransitionGroup)`
  position: relative;
  .overlay-exit-active {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    transition: opacity 300ms ease;
    z-index: 2;
  }
`


export default SwitchTransitionGroup;