import React, { forwardRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import LevelButton from '../components/LevelButton';
import { _w } from '../utils/wordingSystem';

const LevelList = forwardRef(({ allow }, ref) => {
  const [ level, setLevel ] = useState(1);
  const wording = _w('game');
  
  useEffect(() => {
    ref.current = level;
  }, [level])

  function handleClickLevel(e) {
    const level = Number(e.currentTarget.dataset.level);
    if (allow >= level) {
      setLevel(level)
      ref.current = level;
    }
  }
  
  return (
    <Root>
      <li><LevelButton data-level="1" data={wording.level1} selected={level === 1} enable={allow >= 1} onClick={handleClickLevel} /></li>
      <li><LevelButton data-level="2" data={wording.level2} selected={level === 2} enable={allow >= 2} onClick={handleClickLevel} /></li>
      <li><LevelButton data-level="3" data={wording.level3} selected={level === 3} enable={allow >= 3} onClick={handleClickLevel} /></li>
    </Root>
  )
})

const Root = styled.ul`
  padding: 30px 40px;
  box-sizing: border-box;
  > li {
    margin-bottom: 12px;
  }
`

export default LevelList;