import Phaser from 'phaser';
import { createBackground } from './createBackground';
import { InteractGameObject } from './createGameObject';
import { CameraController } from './createCameraController';

export class GameScene extends Phaser.Scene
{
  constructor () {
    super({ key: 'GameScene' });
  }

  create () {
    // background
    const { background } = this.game;
    this.background = createBackground(this, background.width, background.height);

    // objects
    this.gameObjects = [];
    const { resouces } = this.game;
    resouces.forEach(resouce => {
      const object = new InteractGameObject(this, resouce);
      object.onClick(this.handleCheckGameStatus);
      this.gameObjects.push(object);
    })
    
    // make game object interactive
    this.input.on('gameobjectup', (_, gameObject) => gameObject.emit('clicked', gameObject));

    // camera controller
    this.cameraController = new CameraController(this, this.background);
    this.cameraController.applyInteract();

    // game index
    this.currentIndex = 0;
  }

  handleCheckGameStatus(target) {
    if (this.cameraController.camera.moving) return;

    const { resouces, goal } = this.game;

    if (target.name === resouces[this.currentIndex].name ) { 
      target.off();
      if (this.currentIndex < goal) this.currentIndex++;
      if (this.currentIndex === goal) {
        setTimeout(() => this.handleGameWin(), 300);
      };
      document.getElementById('audio-corrent').play();
    }
    else {
      document.getElementById('audio-wrong').play();
    }

  }

  handleGameWin () {
    this.game.onFinish();
  }
}
