import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import { colors } from '../constants/colors';
import Button from '../components/Button';
import { SoundOn, SoundOff } from '../components/Icons';

import { respondTo } from '../utils/responsive';
import { useResoucesLoader } from '../utils/useResoucesLoader';
import { _w, nl2br } from '../utils/wordingSystem';

import preload from './preload.json';
import audio from '../store/audio';

const LoadingScreen = ({ onStart }) => {
  const wording = _w('loading');
  const percent = useResoucesLoader(preload.list);
  const [ loaded, setLoaded ] = useState(false);

  const muted = useSelector(state => state.audio.muted);
  const dispatch = useDispatch();

  function prettifyPercentage(num) {
    return Math.floor(Math.sqrt(num)*10);
  }

  function handleSoundClick() {
    dispatch(audio.actions.toggleMuted());
  }

  useEffect(() => {
    percent === 100 && setLoaded(true);
  }, [percent])
  
  return (
    <Root>
      <Header>
        <SoundButton onClick={handleSoundClick}>
            { muted ? <SoundOff/> : <SoundOn/> }
        </SoundButton>        
      </Header>
      <Middle>
        {/* <Logo src="/images/logo.svg" alt="" /> */}
        {/* <Title>{ wording.title }</Title> */}
        { !loaded &&
            <ProgressContainer>
                <Progress percent={prettifyPercentage(percent)} hide={loaded}>
                    <div className="bar"/>
                </Progress>
                <div className="count">{ prettifyPercentage(percent) }%</div>
            </ProgressContainer>
        }
        { loaded &&
            <StartButton disabled={!loaded} onClick={onStart}>{ wording.start }</StartButton>
        }
      </Middle>
      <Bottom>
        <Copyright>{ nl2br(wording.copyright) }</Copyright>
      </Bottom>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 100%;
  background: ${colors.pink};
  background-image: url(/images/loading-bg-v2.png);
  background-size: cover;
  background-position: center center;
`

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 12px 24px;
  box-sizing: border-box;
  ${respondTo.md} {
    position: fixed;
  }
`

const SoundButton = styled.button`
    border: 0;
    background-color: transparent;
    color: ${ colors.black };
`

const Middle = styled.div`
  position: absolute;
  width: 100%;
  bottom: 96px;
`

const Bottom = styled.div`
   position: absolute;
  width: 100%;
  bottom: 40px;
`

const Logo = styled.img`
  display: block;
  margin: 0 auto;
  margin-bottom: 32px;
  max-width: 100%;
`

const Title = styled.div`
  text-align: center;
  font-size: 24px;
  margin-bottom: 32px;
`

const startButtonEnter = keyframes`
    from {
        transform: translateY(-8px);
        opacity: 0;
    }
    to {
        transform: translateY(0px);
        opacity: 1;
    }
`

const StartButton = styled(Button)`
  display: block;
  margin: 0 auto;
  /* margin-top: 32px; */
  width: calc(100% - 64px);
  font-family: protipo;
  font-weight: 500;
  text-transform: uppercase;
  animation: ${startButtonEnter} .5s ease-out;
  &:disabled {
    opacity: 0.5;
  }
`

const Copyright = styled.div`
  text-align: center;
  line-height: 20px;
  font-family: protipo;
  font-size: 12px;
  font-weight: 500;
`

const ProgressContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    .count {
        margin-left: 8px;
        min-width: 40px;
        text-align: center;
        font-weight: 500;
    }
`

const Progress = styled.div`
  flex: 1;
  flex-shrink: 0;
  /* margin: 0 auto; */
  /* margin-top: 36px; */
  border-radius: 50em;
  max-width: 65%;
  padding: 1px;
  height: 12px;
  background: ${colors.black};
  transition: opacity .3s ease;
  /* ${({ hide }) => hide && css`opacity: 0.33`}; */
  .bar {
    position: relative;
    width: ${({percent}) => percent}%;
    height: 100%;
    background: ${colors.pink};
    border-radius: 50em;
    transition: width .3s;
    img {
      position: absolute;
      right: 0;
      bottom: calc(100% + 4px);
      transform: translateX(50%);
      
    }
    .count {
      position: absolute;
      right: 0;
      bottom: calc(100% + 4px);
      font-family: protipo;
      font-size: 14px;
      transform: translateX(50%);
    }
  }
`


export default LoadingScreen;