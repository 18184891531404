import Phaser from 'phaser';
import { GameScene } from './GameScene';
import { UIScene } from './UIScene';
import { PreloadScene } from './PreloadScene';
import { shuffle } from '../utils/shuffle';

export function core({ resouces, goal, background, onFinish }) {
  
  const config = {
    type: Phaser.Auto,
    width: 375*2,
    height: 608*2,
    backgroundColor: '#EBA195',
    parent: 'game-container',
    scene: [ PreloadScene, GameScene, UIScene ]
  };

  const game = new Phaser.Game(config);

  // Bind resouces and finish event into game
  game.resouces = shuffle(resouces);
  game.goal = goal;
  game.background = {
    width: 2602,
    height: 1040,
    asset: background
  };
  game.onFinish = onFinish;

  return game;
}
