import { createSlice } from "@reduxjs/toolkit";

const gameScreen = createSlice({
    name: 'gameScreen',
    initialState: {
      'screen': 'intro',
      'loadingStatus': 0,
    },
    reducers: {
      setScreen: (state, action) => {
        state.screen = action.payload;
      },
      setLoadingStatus: (state, action) => {
        state.loadingStatus = action.payload;
      },
    }
});

export default gameScreen;
